<template>
    <div>
        <HeaderXS></HeaderXS>
        <div class="container" id="start">
            <div class="row">
                <h2 class="col-12 text-center">Starten Sie Ihre Autopflege</h2>
            </div>
        </div>
        <div class="container-fluid red-bg ceramic-week" v-if="actionWeek">
            <div class="row">
                <div class="col-12 text-center">
                    <h3>Keramik für alle!</h3>
                    <p>
                        <!-- 25 % Rabatt auf das Außenpaket <br> bei Buchung einer Keramikversiegelung!* -->
                        25 % Rabatt auf das Außenpaket mit Keramikversiegelung!*
                    </p>
                    <a @click.prevent="setActionWeek" :class="{ active: actionWeekSet }" class="button">
                        Angebot buchen
                    </a>
                    <p class="small-text">
                        * Um dieses Angebot nutzen zu können, müssen Sie bis zum 15. August 2021 buchen. Die Reinigung Ihres Fahrzeuges kann dann auch zu einem späteren Zeitraum erfolgen.
                    </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <h3 class="col-12 col-lg-4 offset-lg-4 text-center">Fahrzeugtyp</h3>
            </div>
            <div class="row car-type-swiper">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide class="d-flex">
                        <div class="form-check form-check-inline col-12 text-center">
                            <input class="form-check-input" type="radio" name="carTypeRadio" id="carTypeRadio1" value="compact" v-model="car_type">
                            <label class="form-check-label" for="carTypeRadio1">
                                <div class="white-round compact">&nbsp;</div>
                                Kleinwagen
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="form-check form-check-inline col-12 text-center">
                            <input class="form-check-input" type="radio" name="carTypeRadio" id="carTypeRadio2" value="combi" v-model="car_type">
                            <label class="form-check-label text-center" for="carTypeRadio2">
                                <div class="white-round combi">&nbsp;</div>
                                Limousine/Kombi/Cabrio
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="form-check form-check-inline col-12 text-center">
                            <input class="form-check-input" type="radio" name="carTypeRadio" id="carTypeRadio3" value="suv" v-model="car_type">
                            <label class="form-check-label" for="carTypeRadio3">
                                <div class="white-round suv">&nbsp;</div>
                                SUV
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="form-check form-check-inline col-12 text-center">
                            <input class="form-check-input" type="radio" name="carTypeRadio" id="carTypeRadio4" value="bus" v-model="car_type">
                            <label class="form-check-label" for="carTypeRadio4">
                                <div class="white-round bus">&nbsp;</div>
                                Bus/Transporter
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="form-check form-check-inline col-12 text-center">
                            <input class="form-check-input" type="radio" name="carTypeRadio" id="carTypeRadio5" value="bike" v-model="car_type">
                            <label class="form-check-label" for="carTypeRadio5">
                                <div class="white-round bike">&nbsp;</div>
                                Motorrad
                            </label>
                        </div>
                    </swiper-slide>

                </swiper>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <h3 class="col-12 col-lg-4 offset-lg-4 text-center">Reinigung</h3>
            </div>
            <div class="row special-row">

                <swiper class="swiper" :options="swiperOption" v-if="car_type !== 'bike'">
                    <!-- <swiper-slide class="d-flex">
                        <div class="col-12">
                            <input type="radio" name="cleaningTypeRadio" id="cleaningTypeRadio5" value="winter" v-model="cleaning_type">
                            <label class="row flex-row h-100" for="cleaningTypeRadio5">
                                <div class="col-12 align-self-start">
                                    <img class="img-fluid" src="../assets/winter.jpg" />
                                    <h6> Winterpaket</h6>
                                    <ul class="list-unstyled winter">
                                        <li class="no-plus"><strong>Leistungen</strong></li>
                                        <li v-for="(service, index) in items[car_type]['winter'].services" :key="index">
                                            {{ service }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 price-area align-self-end">
                                    <hr />
                                    <span class="price">{{items[car_type]['winter'].price}} €</span> <span class="tax">inkl. 19 % MwSt.</span>
                                </div>
                            </label>
                        </div>
                    </swiper-slide> -->

                    <swiper-slide class="d-flex">
                        <div class="col-12">
                            <input type="radio" name="cleaningTypeRadio" id="cleaningTypeRadio1" value="premium" v-model="cleaning_type">
                            <label class="row flex-row h-100" for="cleaningTypeRadio1">
                                <div class="col-12 align-self-start">
                                    <img class="img-fluid" src="../assets/premium.jpg" />
                                    <h6> Premiumpaket</h6>
                                    <ul class="list-unstyled premium">
                                        <li class="no-plus"><strong>Leistungen</strong></li>
                                        <li v-for="(service, index) in items[car_type]['premium'].services" :key="index">
                                            {{ service }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 price-area align-self-end">
                                    <hr />
                                    <span class="price">{{items[car_type]['premium'].price}} €</span> <span class="tax">inkl. 19 % MwSt.</span>
                                </div>
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="col-12">
                            <input type="radio" name="cleaningTypeRadio" id="cleaningTypeRadio2" value="comfort" v-model="cleaning_type">
                            <label class="row flex-row h-100" for="cleaningTypeRadio2">
                                <div class="col-12 align-self-start">
                                    <img class="img-fluid" src="../assets/comfort.jpg" />
                                    <h6> Comfortpaket</h6>
                                    <ul class="list-unstyled comfort">
                                        <li class="no-plus"><strong>Leistungen</strong></li>
                                        <li v-for="(service, index) in items[car_type]['comfort'].services" :key="index">
                                            {{ service }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 price-area align-self-end">
                                    <hr />
                                    <span class="price">{{items[car_type]['comfort'].price}} €</span> <span class="tax">inkl. 19 % MwSt.</span>
                                </div>
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="col-12">
                            <input type="radio" name="cleaningTypeRadio" id="cleaningTypeRadio3" value="inside" v-model="cleaning_type">
                            <label class="row flex-row h-100" for="cleaningTypeRadio3">
                                <div class="col-12 align-self-start">
                                    <img class="img-fluid" src="../assets/inside.jpg" />
                                    <h6>Innenpaket</h6>
                                    <ul class="list-unstyled inside">
                                        <li class="no-plus"><strong>Leistungen</strong></li>
                                        <li v-for="(service, index) in items[car_type]['inside'].services" :key="index">
                                            {{ service }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 price-area align-self-end">
                                    <hr />
                                    <span class="price">{{items[car_type]['inside'].price}} €</span> <span class="tax">inkl. 19 % MwSt.</span>
                                </div>
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="col-12">
                            <input type="radio" name="cleaningTypeRadio" id="cleaningTypeRadio4" value="outside" v-model="cleaning_type">
                            <label class="row flex-row h-100" for="cleaningTypeRadio4">
                                <div class="col-12 align-self-start">
                                    <img class="img-fluid" src="../assets/outside.jpg" />
                                    <h6>Außenpaket</h6>
                                    <ul class="list-unstyled outside">
                                        <li class="no-plus"><strong>Leistungen</strong></li>
                                        <li v-for="(service, index) in items[car_type]['outside'].services" :key="index">
                                            {{ service }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 price-area align-self-end">
                                    <hr />
                                    <span class="price">{{items[car_type]['outside'].price}} €</span> <span class="tax">inkl. 19 % MwSt.</span>
                                </div>
                            </label>
                        </div>
                    </swiper-slide>
                </swiper>
                <swiper class="swiper" :options="swiperOption" v-else>
                    <swiper-slide class="d-flex">
                        <div class="col-12">
                            <input type="radio" name="cleaningTypeRadio" id="cleaningTypeRadio1" value="premium" v-model="cleaning_type">
                            <label class="row flex-row h-100" for="cleaningTypeRadio1">
                                <div class="col-12 align-self-start">
                                    <img class="img-fluid" src="../assets/premium_bike.jpg" />
                                    <h6> Premiumpaket</h6>
                                    <ul class="list-unstyled">
                                        <li class="no-plus"><strong>Leistungen</strong></li>
                                        <li v-for="(service, index) in items['bike']['premium'].services" :key="index">
                                            {{ service }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 price-area align-self-end">
                                    <hr />
                                    <span class="price">{{items['bike']['premium'].price}} €</span> <span class="tax">inkl. 19 % MwSt.</span>
                                </div>
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="d-flex">
                        <div class="col-12">
                            <input type="radio" name="cleaningTypeRadio" id="cleaningTypeRadio2" value="basis" v-model="cleaning_type">
                            <label class="row flex-row h-100" for="cleaningTypeRadio2">
                                <div class="col-12 align-self-start">
                                    <img class="img-fluid" src="../assets/basis_bike.jpg" />
                                    <h6> Basispaket</h6>
                                    <ul class="list-unstyled">
                                        <li class="no-plus"><strong>Leistungen</strong></li>
                                        <li v-for="(service, index) in items['bike']['basis'].services" :key="index">
                                            {{ service }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 price-area align-self-end">
                                    <hr />
                                    <span class="price">{{items['bike']['basis'].price}} €</span> <span class="tax">inkl. 19 % MwSt.</span>
                                </div>
                            </label>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="row">
                <h3 class="col-12 col-lg-4 offset-lg-4 text-center">zusätzlich buchbar</h3>
            </div>

            <div class="row">

                <div class="col-12 white-bg">
                    <div>
                        <div class="form-check w100" v-for="(a_service, index) in items[car_type][cleaning_type].additional_service"  :key="index">
                            <input class="form-check-input" type="checkbox" name="additionalService" v-bind:value="index" v-bind:id="`additionalService-${index}`" v-model="additional_service" v-bind:disabled="a_service.disabled">
                          <label class="form-check-label" v-bind:for="`additionalService-${index}`">
                            <span class="additional-service-name">{{ index }}</span> <span v-if="a_service.price" class="additional-service-price">{{ parseFloat(a_service.price).toFixed(2) }} €</span>
                          </label>
                          <p class="pop-over__opener" v-bind:id="`popover-${index}`" v-if="additional_service_text[index]">
                              <svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="info circle fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-info-circle-fill b-icon bi" style="color: rgb(187, 200, 240); font-size: 125%;"><g ><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></g></svg>
                          </p>
                          <b-popover v-bind:target="`popover-${index}`" triggers="click blur" v-if="additional_service_text[index]">
                            <p v-html="additional_service_text[index]"></p>
                           </b-popover>
                        </div>
                    </div>

                    <div class="price-area align-self-end">
                        <hr />
                        <strong>Ihr aktueller Preis</strong><br>
                        <span class="price" v-html="getTotalPrice()"></span> <span class="tax">inkl. 19 % MwSt.</span>
                    </div>
                </div>

                <div class="col-12 info-box">
                    <p class="icon-holder">
                        <svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="info circle fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-info-circle-fill b-icon bi" style="color: rgb(187, 200, 240); font-size: 150%;"><g ><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></g></svg>
                    </p> <p class="info-box-content">Bei der Buchung erfolgt eine Anzahlung in Höhe von 39,90 € vom Gesamtbetrag. Die Restsumme wird bei Abholung ihres Fahrzeugs fällig. Sie können ihre Buchung kostenlos bis zu 24 Stunden vor Terminbeginn unter <a href="mailto:storno@waschprofis.de">storno@waschprofis.de</a> stornieren.</p>
                </div>
            </div>
        </div>

        <div class="container white-bg">
            <div class="row">
                <h3 class="col-12 col-lg-4 offset-lg-4 text-center">Unsere Terminvorschläge</h3>
                <div class="col-12 booking-phone">
                    <p>Wählen Sie aus unseren Terminvorschlägen oder vereinbaren Sie telefonisch Ihren individuellen Wunschtermin unter:<br>0351 285 79 285.</p>
                </div>
                <div class="col-12">
                    <Datepicker v-model="selectedDate" :format="customFormatter" :inline="true" :language="de" :disabled-dates="disabledDates" :monday-first="true" @selected="changeTimepicker" @changedMonth="setYear"></Datepicker>
                </div>
                <div class="col-12">
                    <Timepicker @time="getTime" :selectedDuration="getDuration()" :selectedDate="changedDate" :selectedYear="selectedYear" @lockDays="setDisabledDays" ></Timepicker>
                </div>
                <div class="col-12">
                    <ul class="list-unstyled text-center">
                        <li>* Vormittag</li>
                        <li>Bringen 07:00 bis 8:00 Uhr</li>
                        <li>Abholen 11:00 bis 12:00 Uhr</li>
                        <li><br></li>
                        <li>** Nachmittag</li>
                        <li>Bringen 11:00 bis 12:00 Uhr</li>
                        <li>Abholen 17:00 bis 18:00 Uhr</li>
                        <li><br></li>
                        <li>*** Ganztägig</li>
                        <li>Bringen 07:00 bis 8:00 Uhr</li>
                        <li>Abholen 17:00 bis 18:00 Uhr</li>
                    </ul>
                </div>
                <div class="col-12 text-center alert alert-danger" v-if="error_message">
                    <strong>{{error_message}}</strong>
                </div>
                <div class="col-12 text-center">
                    <a @click="addToCart()" class="button">Termin Buchen</a>
                </div>
            </div>
        </div>
        <FooterXS></FooterXS>
    </div>
</template>
<script>
    import Datepicker from 'vuejs-datepicker';
    import Timepicker from "@/components/Timepicker.vue";
    import HeaderXS from "@/components/HeaderXS.vue";
    import FooterXS from "@/components/FooterXS.vue";
    import {de} from 'vuejs-datepicker/dist/locale/'
    import {parse, parseISO, format, addMinutes} from 'date-fns'
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

    export default {
        name: 'CarTypePicker',

        components: {
            Datepicker,
            Timepicker,
            HeaderXS,
            FooterXS,
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        data() {
            return {
                car_type: this.get_car_type(),
                cleaning_type: this.get_cleaning_type(),
                additional_service: this.get_additional_service(),
                booking_time: this.get_booking_time(),
                preperation_location: this.get_preperation_location(),
                duration: 0,
                additional_service_text: {
                    'Mietwagen':
                        `Der Mietwagen steht Ihnen über die gesamte Dauer Ihrer gebuchten Autopflege zur Verfügung. Er muss bei Abgabe nicht betankt werden und enthält 50 frei Kilometer. <br>
                        Sollten Sie über einen Gutscheincode für einen gratis Mietwagen verfügen, so lassen Sie den Haken bei Mietwagen weg. Im nächsten Schritt der Buchung geben Sie einfach den Gutscheincode in das dafür vorgesehene Feld ein.`,
                    'Hol- & Bring-Service':
                        `Wir holen Ihr Auto in Dresden ab und bringen es Ihnen nach der Reinigung zu Ihrer Wunschadresse in Dresden. Für Ihre Mobilität übergeben wir Ihnen vor Ort ein Fahrzeug unserer Mietwagenflotte. `,
                    'Nanoversiegelung':
                        `Eine Nanoversiegelung führt zum sogenannten Lotuseffekt.<br>
                        Wie an der namensgebenden Lotusblüte perlen dann auch von der Versiegelung Wasser, Öl und Staub einfach ab.  Eine Nanoversiegelung lässt sich auf fast allen Oberflächen anwenden, besonders beliebt ist sie aber am Autolack, den Scheiben und den Felgen.`,
                    'Mattlack-Nanoversiegelung':
                        `Eine Mattlack-Nanoversiegelung führt zum sogenannten Lotuseffekt.<br>
                        Wie an der namensgebenden Lotusblüte perlen dann auch von der Versiegelung Wasser, Öl und Staub einfach ab.`,
                    'Keramikversiegelung':
                        `Bei der Keramikversiegelung werden nicht nur feinste Lackuntiefen aufgefüllt, sondern zusätzlich der gesamte Lack mit einer Beschichtung überzogen. Es handelt sich also um ein dauerhaftes, festes Verbundsystem.`,
                    'Felgenversiegelung':
                        `Eine Felgenversiegelung bewahrt den Glanz der Felge und verstärkt diesen sogar. Bremsstaub und andere Verschmutzungen können leichter entfernt werden und setzen sich nicht wieder so schnell fest.`,
                    'Ozonbehandlung':
                        `Bei der Ozonbehandlung wird das Ozon mit einem Ozongerät erzeugt und im Auto freigesetzt. Die Ozonbehandlung im Auto neutralisiert nicht nur unangenehme Gerüche wie z.B. Nikotin sondern beseitigt auch Bakterien, Pilze und Keime auf molekularer Ebene. `,
                    'Himmelreinigung':
                        `Bei der Himmelreinigung werden Handgriffe, Säulen und der Dachhimmel schonend mit Spezialreiniger shampooniert. Hartnäckige Flecken und sogar Nikotinbelag werden angelöst und mit hochwertigen Mikrofasertüchern entfernt.`,
                    'Lederpflege':
                        `Die Lederpflege erfüllt verschiedene Funktionen, dazu zählt der Schutz vor UV-Licht, die Geschmeidigkeit und Elastizität (Rückfettung), Geruchsänderung, Farberhaltung und Schmutzabweisung.`,
                    'Motorwäsche':
                        `Gründliche und schonend Reinigung des Motorraum mit Hochdruck und anschließender Konservierung vor Korrosion und Umwelteinflüssen.`,
                },
                de: de,
                disabledDates: {
                    to: new Date(new Date().setDate(new Date().getDate())), // Disable all dates up to today
                    days: [0, 6], // Disable weekends
                    dates: [ // Disable an array of dates
                      new Date(2021, 3, 2),
                      new Date(2021, 3, 5),
                    ],
                    customPredictor: function(date) {
                        // disables the date if it is a multiple of 5
                        if(date.getDate() % 5 == 0){
                            // return true
                        }
                    }
                },
                selectedDate: null,
                changedDate: null,
                swiperOption: {
                  slidesPerView: 'auto',
                  centeredSlides: true,
                  spaceBetween: 20,
                },
                error_message: '',
                actionWeek: false,
                actionWeekSet: false,
                selectedYear: new Date()
            }
        },
        computed: {
          items() { return this.$store.state.items },
        },
        watch: {
            car_type: function(){
                if(this.actionWeekSet && this.cleaning_type === 'outside'){
                    this.additional_service = ['Keramikversiegelung'];
                }else{
                   this.additional_service = [];
                }
                if((this.car_type === 'bike' && this.cleaning_type !== 'premium') || (this.car_type === 'bike' && this.cleaning_type !== 'basis') || (this.car_type !== 'bike' && this.cleaning_type === 'basis')){
                    this.cleaning_type = 'premium';
                }
            },
            cleaning_type: function(){
                if(this.actionWeekSet && this.cleaning_type === 'outside'){
                    this.additional_service = ['Keramikversiegelung'];
                }else{
                   this.additional_service = [];
                }
            },
            additional_service: function(newVal){
                var a_service = this.items[this.car_type][this.cleaning_type].additional_service,
                    price,
                    duration,
                    el;

                if(this.cleaning_type === "premium"){
                    el = this.findByText(" Hartwachsversiegelung ", this.cleaning_type)
                }else if(this.cleaning_type === "comfort" || this.cleaning_type === "outside"){

                    el = this.findByText(" Lackversiegelung ", this.cleaning_type)
                }

                if(el){
                    if(newVal.includes('Nanoversiegelung') || newVal.includes('Keramikversiegelung') || newVal.includes('Mattlack-Nanoversiegelung')){
                        el.style.color = 'grey';
                    }else{
                        el.style.removeProperty("color");
                    }
                }

                if(newVal.includes('Nanoversiegelung') || this.cleaning_type === "inside" || this.car_type === "bike"){
                    a_service['Keramikversiegelung'].disabled = 'disabled';
                    a_service['Mattlack-Nanoversiegelung'].disabled = 'disabled';
               }else if(this.cleaning_type !== "inside" && !newVal.includes('Mattlack-Nanoversiegelung') && !newVal.includes('Keramikversiegelung')){
                    price = a_service['Keramikversiegelung'].price;
                    duration = a_service['Keramikversiegelung'].duration;

                    a_service['Keramikversiegelung'] = {
                        'duration': duration,
                        'price': price,
                    }
                    price = a_service['Mattlack-Nanoversiegelung'].price;
                    duration = a_service['Mattlack-Nanoversiegelung'].duration;

                    a_service['Mattlack-Nanoversiegelung'] = {
                        'duration': duration,
                        'price': price,
                    }
               }
               if(newVal.includes('Keramikversiegelung') || this.cleaning_type === "inside" || this.car_type === "bike"){
                    a_service['Nanoversiegelung'].disabled = 'disabled';
                    a_service['Mattlack-Nanoversiegelung'].disabled = 'disabled';
               }else if(this.cleaning_type !== "inside" && !newVal.includes('Mattlack-Nanoversiegelung') && !newVal.includes('Nanoversiegelung')){
                    price = a_service['Nanoversiegelung'].price;
                    duration = a_service['Nanoversiegelung'].duration;

                    a_service['Nanoversiegelung'] = {
                        'duration': duration,
                        'price': price,
                    }
                    price = a_service['Mattlack-Nanoversiegelung'].price;
                    duration = a_service['Mattlack-Nanoversiegelung'].duration;

                    a_service['Mattlack-Nanoversiegelung'] = {
                        'duration': duration,
                        'price': price,
                    }
               }
               if(newVal.includes('Mattlack-Nanoversiegelung') || this.cleaning_type === "inside" || this.car_type === "bike"){
                    a_service['Nanoversiegelung'].disabled = 'disabled';
                    a_service['Keramikversiegelung'].disabled = 'disabled';
               }else if(this.cleaning_type !== "inside" && !newVal.includes('Keramikversiegelung') && !newVal.includes('Nanoversiegelung')){
                    price = a_service['Nanoversiegelung'].price;
                    duration = a_service['Nanoversiegelung'].duration;

                    a_service['Nanoversiegelung'] = {
                        'duration': duration,
                        'price': price,
                    }
                    price = a_service['Keramikversiegelung'].price;
                    duration = a_service['Keramikversiegelung'].duration;

                    a_service['Keramikversiegelung'] = {
                        'duration': duration,
                        'price': price,
                    }
               }
            },
            duration: function(newVal){
                if(newVal >= 300){
                    this.items[this.car_type][this.cleaning_type]['additional_service']['Mietwagen'].price = 29.9;
                }else{
                    this.items[this.car_type][this.cleaning_type]['additional_service']['Mietwagen'].price = 19.9;
                }
            }
        },
        methods: {
            setYear(date){
                if('timestamp' in date) {
                    date = new Date(date.timestamp)
                }
                this.selectedYear = date;
            },
            setDisabledDays(dates){
                var disabled = []
                dates.forEach(date => {
                    disabled.push(parse(date, 'dd.MM.yyyy', new Date()))
                })
                this.disabledDates.dates = disabled
            },
            changeTimepicker(date){
                this.changedDate = date;
            },
            get_car_type() {
              if(this.$store.state.cart.length){
                  return this.$store.state.cart.product.split("_")[0];
              }else{
                  return 'compact';
              }
            },
            get_cleaning_type() {
              if(this.$store.state.cart.length){
                  return this.$store.state.cart.product.split("_")[1];
              }else{
                  return 'winter';
              }
            },
            get_additional_service() {
              if(this.$store.state.cart.length){
                  return this.$store.state.cart.additional_service;
              }else{
                  return [];
              }
            },
            get_booking_time() {
              if(this.$store.state.cart.length){
                  return this.$store.state.cart.booking_time;
              }else{
                  return "";
              }
            },
            get_preperation_location() {
              if(this.$store.state.cart.length){
                  return this.$store.state.cart.preperation_location;
              }else{
                  return 1;
              }
            },
            getTime (value) {
              this.error_message = "";
              this.booking_time = value.date;
              this.preperation_location = value.prepLocation;
            },
            getDuration(){
                var duration = this.items[this.car_type][this.cleaning_type].duration,
                    additional_service = this.items[this.car_type][this.cleaning_type].additional_service;

                if(this.additional_service.length > 0){
                    this.additional_service.forEach(value=>{
                        duration += additional_service[value].duration;
                    })
                }
                this.duration = duration;
                return duration;
            },
            customFormatter(date) {
                return format(date, 'yyyy-MM-dd');
            },
            getTotalPrice(){
                  var cleaning = this.items[this.car_type][this.cleaning_type].price,
                      additional_service = this.items[this.car_type][this.cleaning_type].additional_service,
                      additional = 0,
                      hasCaramic = false;

                  if(this.additional_service.length > 0){
                      this.additional_service.forEach(value=>{
                          additional += additional_service[value].price;
                          if(value === "Keramikversiegelung"){
                            hasCaramic = true;
                          }
                      })
                  }

                  if(this.actionWeek && this.cleaning_type === "outside" && hasCaramic){
                    var price = parseFloat(cleaning + additional);
                    price = (price * 75 ) / 100;
                    return `<span style="text-decoration:line-through">${parseFloat(cleaning + additional).toFixed(2)} €</span><br>${price.toFixed(2)} €`
                  }else{
                    return `${parseFloat(cleaning + additional).toFixed(2)} €`;
                  }
            },
            addToCart() {
                if(this.booking_time !== ""){
                    this.$store.commit({
                        type: 'addToCart',
                        product: `${this.car_type}_${this.cleaning_type}`,
                        additional_service: this.additional_service,
                        booking_time: this.booking_time,
                        preperation_location: this.preperation_location,
                        booking_end_time: format(addMinutes(parseISO(this.booking_time), this.getDuration()), 'yyyy-MM-dd HH:mm'),
                        duration: this.getDuration(),
                    })
                    this.$router.push({name: 'BookingForm'})
                }else{
                    this.error_message = "Bitte geben Sie einen Termin an."
                }
            },
            findByText(text, el = false){

                var aTags,
                    searchText = text,
                    found;

                if(el){
                    aTags = document.querySelector("." + el).children
                }else{
                    aTags = document.getElementsByTagName("li")
                }

                for (var i = 0; i < aTags.length; i++) {
                  if (aTags[i].textContent == searchText) {
                    found = aTags[i];
                    break;
                  }
                }
                return found;
            },
            setActionWeek(){
                if(!this.actionWeekSet){
                    this.cleaning_type = "outside";
                }else{
                    this.cleaning_type = "premium";
                }
                this.actionWeekSet = !this.actionWeekSet;
            }
        },
        mounted() {
            if(new Date() < new Date('August 15, 2021 23:59:59')){
                this.actionWeek = true;
            }
        }
    }
</script>
<style lang="scss" scoped>

    h2{
        font-size: 28px;
        letter-spacing: 0.7px;
        line-height: 34px;
        margin-bottom: 15px;
    }

    h3{
        font-family: 'futura-pt-bold';
        font-size:20px;
        letter-spacing: 0.5px;
        line-height:42px;
    }

    .car-type-swiper{
        margin-bottom: 60px;
    }

    .swiper-slide {
        width: 75%;
    }

    .form-check-inline{
        margin-right: 0;
        width:370px;
        padding-right:0;

        input{
            display:none;
        }

        input:checked+label{
            background-color: #bbc8f0;
        }

        label{
            font-size: 21px;
            letter-spacing: 0.53px;
            line-height: 60px;
            width:100%;
            background-color: #edeef0;
            padding-top: 25px;


            .white-round{
                width: 100%;
                height: 120px;

                &.compact{
                    background: url('../assets/compact.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.combi{
                    background: url('../assets/combi.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.suv{
                    background: url('../assets/suv.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.bus{
                    background: url('../assets/bus.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.bike{
                    background: url('../assets/bike.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .white-bg{
        padding-bottom: 55px;

        &>div{
            padding-left: 5px;
            padding-right: 5px;

            &>div{
                margin-bottom: 20px;
                font-size: 19px;
                position: relative;

                .form-check-input{
                    margin-top:0.4rem;
                    margin-right: 10px;
                }

                .form-check-label{
                    width: 90%;
                    padding-left: 10px;
                }

                .additional-service-name{
                    display: inline-block;
                    float:left;
                }

                .additional-service-price{
                    display: inline-block;
                    right: 3em;
                    float:right;
                }

                .pop-over__opener{
                    display: inline-block;
                    right: 0;
                    position: absolute;
                }
            }
        }
    }

    .icon-holder{
        font-weight:500;
        display: inline-block;
        padding-right: 0.6em;
    }

    .special-row{

        margin-bottom: 60px;

        .col-12{
            padding-left:0;
            padding-right:0;

            img{
                margin-bottom: 35px;
            }

            input{
                display:none;
            }

            input:checked+label{
                background-color: #bbc8f0;
            }

            label{

                background-color: #edeef0;
                padding-bottom: 20px;
                margin-left:0;
                margin-right:0;
                width:100%;

                .col-12{
                    padding-left:0;
                    padding-right:0;

                    &.price-area{
                      padding-right:25px;
                      padding-left:25px;
                    }

                }

                .form-check-input{
                    margin-left: 0;
                    padding-left:35px;
                }

                h6{
                    font-family: 'futura-pt-bold';
                    font-size: 28px;
                    color: #FFF;
                    letter-spacing: 0.7px;
                    padding-right:35px;
                    padding-left:35px;
                    margin-top: -45%;
                }

                ul{
                    font-size: 19px;
                    margin-top:100px;
                    padding-left:25px;
                    padding-right:25px;
                    margin-bottom:0;

                    li {
                        list-style-position: inside;
                        text-indent: -1em;
                        padding-left: 1em;

                        &:before{
                            content: "+";
                        }

                        &.no-plus{
                            &:before{
                                content: "";
                            }
                        }
                    }
                }
            }
        }
    }

    .button{
        margin-top:30px;
        width:100%;
    }

    .alert-danger{
        margin-top:30px;
    }

    .booking-phone{
        margin-bottom: 25px;
        line-height: 1.15em;
    }



    .ceramic-week{

        margin-bottom: 60px;

        h3{
            font-family: 'futura-pt-bold';
            text-transform: uppercase;
            color: #FFF;
            font-size: 29px;
            margin-top:35px;
            margin-bottom:20px;
        }

        p{
            font-family: 'futura-pt-bold';
            font-size: 20px;

            &.small-text{
                margin-top:10px;
                font-family: 'futura-pt';
                text-align: left;
                font-size: 16px;
            }
        }

        a{
            margin-top:20px;
            margin-bottom:25px;
            color: #000;
            background-color: #FFF;
            border-color: #FFF;

            &:hover{
                background-color: #bbc8f0;
                border-color: #bbc8f0;
            }

            &.active{
                background-color: #bbc8f0;
                border-color: #bbc8f0;
            }
        }
    }

</style>

<style lang="scss" >

    .vdp-datepicker{
        font-size: 21px;
        margin-bottom:40px;

        .vdp-datepicker__calendar{
            width: 100%;

            .cell{
                height: 55px;
                line-height: 55px;
            }
        }
    }

    .info-box{
        display: flex;
        margin-bottom:60px;
    }
</style>